import React, { useState, useEffect } from 'react';
import { Box, Button, List, ListItem, Link, Typography,CircularProgress,Alert } from '@mui/material';
import { fetchUserData, updateUserDetails } from 'setup/db_calls';
import { useAuth } from 'setup/auth';
import firebaseApp from 'setup/firebase';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import "pages/user_profile/medical_info/index.scss";

export default function ProfileMedical() {
  const { currentUser } = useAuth();
  const [medicalAllergies, setMedicalAllergies] = useState('');
  const [medicalDietaryReq, setMedicalDietaryReq] = useState('');
  const [medicalDocFile, setMedicalDocFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [medicalDocs, setMedicalDocs] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [loadingPFP, setLoadingPFP] = useState(null); 
  const [loadingSubmit, setLoadingSubmit] = useState(null); 

  


  useEffect(() => {
    async function fetchData() {
      if (currentUser && currentUser.email) {
        const data = await fetchUserData(currentUser.email);
        if (data) {
          setMedicalAllergies(data.medicalallergies || '');
          setMedicalDietaryReq(data.medicaldietaryreq || '');
          setMedicalDocs(data.medicalDocs || []);
        }
      }
    }

    fetchData();
  }, [currentUser]);

  const handleFileChange = (event) => {
    setMedicalDocFile(event.target.files[0]);
  };

  const uploadDocument = async () => {
    setLoadingPFP('wait')
    if (!medicalDocFile || !currentUser) 
    {
      setLoadingPFP('Please Upload medical information')
      return;

    }
    else{

    try {
      
    
    setUploading(true);
    const storage = getStorage(firebaseApp);
    const storageRef = ref(storage, `medical_documents/${currentUser.uid}/${medicalDocFile.name}`);
    await uploadBytes(storageRef, medicalDocFile);
    const url = await getDownloadURL(storageRef);
  
    const userDetails = await fetchUserData(currentUser.email);
    const updatedDetails = {
      ...userDetails,
      medicalDocs: userDetails.medicalDocs ? [...userDetails.medicalDocs, url] : [url]
    };
  
    await updateUserDetails(currentUser.email, updatedDetails);
  
    setMedicalDocs(prevDocs => [...prevDocs, url]);
    setUploading(false);
    setLoadingPFP("succesfull")

    // alert('Document uploaded successfully.');
  }catch (error) {
    setLoadingPFP(error)
        
  }
} 

  };

  useEffect(() => {
    if (medicalAllergies && medicalDietaryReq && medicalDocs.length > 0) {
      setIsComplete(true);
    } else {
      setIsComplete(false);
    }
  }, [medicalAllergies, medicalDietaryReq, medicalDocs]);

  const handleSubmit = async () => {
    setLoadingSubmit("wait")
    if (currentUser && currentUser.email && isComplete) {
      const updatedData = {
        medicalallergies: medicalAllergies,
        medicaldietaryreq: medicalDietaryReq,
        medicaldoc: medicalDocs,
        medicalstatus: true 
      };
      await updateUserDetails(currentUser.email, updatedData);
    setLoadingSubmit("succesfull")

      // alert('Profile updated successfully.');
    }
  };

  return (
    <div className="profile-medical">
      <Box className="profile-medical-container">
      <Typography variant="h4" className="section-title">Medical Profile</Typography>

      <Box className="input-section">
        <Typography variant="h6" className="input-label">Medical Allergies</Typography>
        <textarea
          value={medicalAllergies}
          onChange={(e) => setMedicalAllergies(e.target.value)}
        ></textarea>
      </Box>

      <Box className="input-section">
        <Typography variant="h6" className="input-label">Medical Dietary Requirements</Typography>
        <textarea
          value={medicalDietaryReq}
          onChange={(e) => setMedicalDietaryReq(e.target.value)}
        ></textarea>
      </Box>

      <Box className="input-section">
        <Typography variant="h6" className="input-label">Upload Medical Document</Typography>
        <input type="file" onChange={handleFileChange} />
        <Button className="uploadButton" variant="contained" onClick={uploadDocument} disabled={uploading}>
          {uploading ? 'Uploading...' : 'Upload Document'}
        </Button>
        {(loadingPFP=== null)?("")
                :(loadingPFP=== "wait")?
                              (<Box sx={{ display: 'flex' }}>
                                          <CircularProgress />
                                          </Box>)
                :(loadingPFP=== "succesfull")?
                                  (<Alert severity="success">
                                  successful
                                  </Alert>)
                :(<Alert severity="error"> 
                    {loadingPFP}
                  </Alert>)}
      </Box>

      {/* Display uploaded documents */}
      <Box className="document-list">
        <List>
          {medicalDocs.map((docUrl, index) => (
            <ListItem key={index} className="list-item">
              <Link href={docUrl} target="_blank" rel="noopener noreferrer">
                Document {index + 1}
              </Link>
            </ListItem>
          ))}
        </List>
      </Box>

      <Box className='buttonder'>
        <Button className="submit-button" onClick={handleSubmit} disabled={!isComplete || uploading}>
          Submit
        </Button>
        {(loadingSubmit=== null)?("")
                :(loadingSubmit=== "wait")?
                              (<Box sx={{ display: 'flex' }}>
                                          <CircularProgress />
                                          </Box>)
                :(loadingSubmit=== "succesfull")?
                                  (<Alert severity="success">
                                  successful
                                  </Alert>)
                :(<Alert severity="error"> 
                    {loadingSubmit}
                  </Alert>)}
      </Box>
      </Box>
    </div>
  );
}
