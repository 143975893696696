import AdminDash from 'pages/admin_dash';
import './App.scss';
import Login from 'pages/login';
import Layout from 'setup/layout';
import RequireAuth from 'setup/requireAuth';
import {
  Route,
  Routes,
} from "react-router-dom";
import AdminDB from 'pages/admin_DB';
import AdminUserMan from 'pages/admin_user_management';
import DelegateDash from 'pages/delegate_dash';
import ForgetPass from 'pages/forgot_password';
import Notifications from 'pages/notifications';
import Signup from 'pages/signup';
import ProfilePersonal from 'pages/user_profile/personal_info';
import ProfileMedical from 'pages/user_profile/medical_info';
import ProfilePayement from 'pages/user_profile/payment_info';
import ProfileTravel from 'pages/user_profile/travel_info';
import ProfileContract from 'pages/user_profile/contract_info';
import Index from 'pages/test';
import Forbidden from 'pages/forbidden';
// import Index from 'pages/user_profile/contract_info';

// import PrivateRoutes from "setup/privateroutes";
// import PublicRoutes from "setup/publicroutes";

function App() {
  return (
    

      <Routes>
          {/* Public routes */}
        <Route index element={<Login />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/forgot-password" element={<ForgetPass />} />
        <Route path="/403" element={<Forbidden />} />
      
        <Route element = {<RequireAuth allowedRoles = {['2001']}/>}>
          
          {/* delegate */}
            <Route path="/delegate-dashboard" element={<DelegateDash />} />  
            <Route path="/personal-profile" element={<ProfilePersonal />} />
            <Route path="/medical-profile" element={<ProfileMedical />} />
            <Route path="/payment-profile" element={<ProfilePayement />} />
            <Route path="/travel-profile" element={<ProfileTravel />} />
            <Route path="/contract-profile" element={<ProfileContract />} />
          </Route>


          <Route element = {<RequireAuth allowedRoles = {['2009']}/>}>
          {/* admin */}
            <Route path="/admin-dashboard" element={<AdminDash />} />
            <Route path="/admin-database" element={<AdminDB />} />
            <Route path="/notifications" element={<Notifications />} /> 
            {/* <Route path="/admin-database" element={<AdminDB />} /> */}
            <Route path="/admin-user-management" element={<AdminUserMan />} />

          </Route>
        {/* catch all */}
        {/* <Route path='*' element = {<missing/>}/> */}

          
          
        
      </Routes> 

   
   
        
  );
}

export default App;
   /* 
        <Route exact path="/" element={<Login/>} />   
        <Route path="/admin-dashboard" element={<AdminDash />} />
        <Route path="/admin-database" element={<AdminDB />} />
        <Route path="/admin-user-management" element={<AdminUserMan />} />
        <Route path="/delegate-dashboard" element={<DelegateDash />} />  
        <Route path="/forgot-password" element={<ForgetPass />} />
        <Route path="/admin-notifications" element={<Notifications />} />
        <Route path="/sign-up" element={<Signup />} />
        <Route path="/personal-profile" element={<ProfilePersonal />} />
        <Route path="/medical-profile" element={<ProfileMedical />} />
        <Route path="/payment-profile" element={<ProfilePayement />} />
        <Route path="/travel-profile" element={<ProfileTravel />} />
        <Route path="/contract-profile" element={<ProfileContract />} /> */