import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useAuth } from 'setup/auth';
import "pages/delegate_dash/index.scss"
import { GetPersonalNotifications } from 'setup/db_calls';
import { GetNotifications } from 'setup/db_calls';
import { Link } from 'react-router-dom';

 function DelegateDash() {
    const {currentUser,userInfo } = useAuth();
    const [notifications, setNotifications] = useState(null);
    const [data, setData] = useState(null);

  useEffect(() => {
    const GetInfo = async () => {
      try {
        const response = await GetPersonalNotifications(currentUser);
        const holder = response.reverse()
        setData(holder);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    const GetNotiInfo = async () => {
      try {
        const querry = await GetNotifications()
        const holder = querry.reverse()
        setNotifications(holder)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    GetInfo();
    GetNotiInfo();
  }, []);
  
  return (
   <Box className = "delegateDash">
    {/* NOTIFICATIONS */}
    <Box className = "notifications">
    <Stack className='stackbox' direction="column" spacing={2} >
    {notifications? (
          notifications.map((results, index) => (

            userInfo.userYear===results['notificationYear']?
          
            <Card sx={{ minWidth: 275 }} key={index}>
              <CardContent className='CardCont'>
                <Typography sx={{ fontSize: 14 }} className='Typ' color="text.secondary" gutterBottom>
                  {results['notificationYear']}
                </Typography>
                <Typography variant="h5" component="div"  className='Typ'>
                  {results['notificationHeader']}
                </Typography>
                <Typography variant="body2" component="div"  className='Typ'>
                  {results['notificationInfo']}
                </Typography>
                {results['notificationLink'] !== "NA" && (
                <Link  target="_blank" to={results['notificationLink']} variant="body2" className='Typ'>
                {results['notificationLink']}
              </Link>
                
               )}
                <Typography variant="body2"  className='Typ'>
                {new Date(results['notificationTimeDate'] * 1000)
                                .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'})}
                  
                </Typography>
              </CardContent>
            </Card>
            : null
          ))
        
        ) : (
          <Typography>No notifications available.</Typography>
         
        )}
      
    </Stack>
    </Box>
    {/* END NOTIFICATIONS */}

    {/* PERSONAL NOTIFICATIONS */}
    <Box className = "personalNotifications">
    <Stack className='stackbox' direction="column" spacing={2} >
      
      {data ? (
        data.map((element, key) => (
          <Card sx={{ minWidth: 275 }} key={key}>
            <CardContent>
            <Typography sx={{ fontSize: 16 }} variant="h5" component="div">
                {element.personalNotificationsText}
              </Typography>
              <Typography variant="body2">
                {new Date(element.personalNotificationsDate.seconds*1000)
                                .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'})}
                </Typography>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography>Loading...</Typography>

      )}
      
    </Stack>
    </Box>
    {/* END PERSONAL NOTIFICATIONS */}
   </Box>
    
  )
}

export default DelegateDash