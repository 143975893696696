import { useEffect, useState } from 'react';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL, uploadBytes } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Button } from '@mui/material';
import "pages/user_profile/contract_info/index.scss"

const MyComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [downloadUrl1, setDownloadUrl1] = useState('');
    const [downloadUrl2, setDownloadUrl2] = useState('');
    const [file1, setFile1] = useState(null);
    const [userEmail, setUserEmail] = useState(null); // State for user email
    const [uploading, setUploading] = useState(false); // State for upload button loading

    const db = getFirestore();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserEmail(user.email);
                fetchContractData();
            } else {
                setError(new Error('User is not logged in'));
                setIsLoading(false);
            }
        });

        return () => unsubscribe(); // Cleanup subscription on unmount
    }, [auth]);

    const fetchContractData = async () => {
        try {
            const db = getFirestore();
            const storage = getStorage(); 
            const primaryDocRef = doc(db, "SymcoInfo", "2024");
            const docSnap = await getDoc(primaryDocRef);

            if (!docSnap.exists()) {
                throw new Error("No such primary document in 'SymcoInfo/2024'");
            }

            const data = docSnap.data();

            if (data.contract) {
                const storageRef1 = ref(storage, data.contract);
                const url1 = await getDownloadURL(storageRef1);
                setDownloadUrl1(url1);
            }

            if (data.contract2) {
                const storageRef2 = ref(storage, data.contract2);
                const url2 = await getDownloadURL(storageRef2);
                setDownloadUrl2(url2);
            }
        } catch (err) {
            setError(err);
        } finally {
            setIsLoading(false);
        }
    };

    const uploadFileAndUpdateDb = async (file, storagePath) => {
        if (!file || !userEmail) return;

        setUploading(true); // Start uploading
        const storage = getStorage();
        const storageRef = ref(storage, storagePath);

        try {
            const snapshot = await uploadBytes(storageRef, file);
            const downloadURL = await getDownloadURL(snapshot.ref);
            const userDocRef = doc(db, "Users", userEmail);
            await updateDoc(userDocRef, {
                contractdoc: arrayUnion(downloadURL)
            });
            alert('File uploaded and database updated successfully');
        } catch (err) {
            alert('Upload error: ' + err.message);
        } finally {
            setUploading(false); // End uploading
        }
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    // if (error) {
    //     return <Box>Error: {error.message || error}</Box>;
    // }

    return (
        <Box className="profile-contract"> 
            <Box className="profile-contract-inner">
                <h1>Contract Documents</h1>
                {downloadUrl1 ? (
                    <Box className="contractDown">
                        <h2>RSA Delegate Contract</h2>
                        <a href={downloadUrl1} target="_blank" rel="noopener noreferrer">Download Contract 1</a>
                    </Box>
                ) : (
                    <p>No RSA Delegate Contract available</p>
                )}
                {downloadUrl2 ? (
                    <Box className="contractDown">
                        <h2>International Delegate Contract</h2>
                        <a href={downloadUrl2} target="_blank" rel="noopener noreferrer">Download Contract 2</a>
                    </Box>
                ) : (
                    <p>No contract 2 data available</p>
                )}
                <Box className="upload">
                    <h2 className='contract'>Upload Contract </h2>
                    <input type="file" onChange={(e) => setFile1(e.target.files[0])} />
                    <Button 
                        variant="contained"
                        onClick={() => uploadFileAndUpdateDb(file1, `contracts/${userEmail}/contract1`)}
                        disabled={uploading} // Disable button while uploading
                    >
                        {uploading ? 'Uploading...' : 'Upload'} {/* Change label based on uploading state */}
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default MyComponent;
