import React, { useContext, useState, useEffect } from "react"
import { auth } from "setup/firebase"
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged ,sendPasswordResetEmail, updateEmail, updatePassword ,signOut } from "firebase/auth"
import { collection,doc, getDoc, getFirestore, Timestamp, FieldValue, Filter, getDocs ,query, where,limit} from "firebase/firestore";
import firebaseApp from "setup/firebase";
import { useRef } from "react";
const db = getFirestore(firebaseApp);
const AuthContext = React.createContext()


export function useAuth() {
    return useContext(AuthContext)
  }


  export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [userType, setUserType] = useState()
    const [userInfo, setUserInfo] = useState()

    const [loading, setLoading] = useState(true)
  
    function signup(email, password) {
             
      return createUserWithEmailAndPassword(auth,email, password)
    }
  
     function login(email, password) {
      
      return signInWithEmailAndPassword(auth, email, password)

    }
    
    function logout() {
      return signOut(auth)
    }
  
    function resetPassword(email) {
      return sendPasswordResetEmail(auth,email)
    }
    
     async function GetUserType(user) {

      if (user) {
        try {
          const ref = doc(db, "Users", user.email);
          const docSnap = await getDoc(ref)
          if (docSnap.exists()) {
            setUserType( docSnap.data().usertype);
             return docSnap.data().usertype

          } else {
             setUserType([])
            return []
          } 
        } catch (error) {
          console.log(error)
            setUserType([])
          return []
        }
      }
      
      
        
     }
     async function GetUserInfo(user) {
     
          if (user) {
            try {
              const ref = doc(db, "Users", user.email);
              const docSnap = await getDoc(ref)
              if (docSnap.exists()) {
                setUserInfo( docSnap.data());
                 return docSnap.data()

              } else {
                 setUserInfo([])
                return []
              } 
            } catch (error) {
              console.log(error)
              setUserInfo([])
              return []
            }
          }
         }
        
  
    function updateUserEmail(email) {
      return updateEmail(currentUser,email)
    }
  
    function updateuserPassword(password) {
      return updatePassword(currentUser,password)
    }
  
    useEffect(() => {
      const unsubscribe = onAuthStateChanged(auth,user => {
        setCurrentUser(user)
        GetUserType(user)
        GetUserInfo(user)
        setLoading(false)
      })
  
      return unsubscribe
    }, [])
  
    const value = {
      currentUser,
      userType,
      login,
      signup,
      logout,
      resetPassword,
      updateUserEmail,
      updateuserPassword,
      GetUserType,
      userInfo,
  
         }
  
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    )
  } 