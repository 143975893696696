// import { PieChart } from '@mui/icons-material';
import { Box, Card, CardContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
// import { DBDash } from 'setup/db_calls';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import "pages/admin_dash/index.scss"
import { DBDashStats } from 'setup/db_calls';

export default function AdminDash() {
  // const [userDB, setUserDB] = useState([]);
  const [yearDB, setYearDB] = useState();
  const [persStatusDB, setPersStatusDB] = useState();
  const [contractstatusDB, setcontractstatusDB] = useState();

  const [medicalstatusDB, setmedicalstatusDB] = useState();

  const [paymentstatusDB, setpaymentstatusDB] = useState();
  const [travelstatusDB, settravelstatusDB] = useState();


// contractstatus  medicalstatus paymentstatus travelstatus

  
  useEffect(() => {
    // console.log('Reload.');
    const GetInfo = async () => {
      try {
        
        const response = await DBDashStats();
        // setUserDB(response)
        // console.log(response)
        if (response) {
          // YEAR !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          let year = {};
          response.forEach( ( eventObject ) => {
              if ( year.hasOwnProperty( eventObject.userYear ) ) {
                year[eventObject.userYear]++;
              } else {
                year[eventObject.userYear] = 1;
              }
          } );
          // console.log(year)
          // convert object to array of objects (if this is really needed)
          year = Object.keys( year ).map( ( key ) => { 
              return { 'label': key, 'value': year[key] }
          } );  
           setYearDB(year);
          
          
           // persStatus !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

           let persStatus = {};
          response.forEach( ( eventObject ) => {
              if ( persStatus.hasOwnProperty( eventObject.userstatus ) ) {
                persStatus[eventObject.userstatus]++;
              } else {
                persStatus[eventObject.userstatus] = 1;
              }
          } );
          // console.log(year)
          // convert object to array of objects (if this is really needed)
          persStatus = Object.keys( persStatus ).map( ( key ) => { 
              return { 'label': key, 'value': persStatus[key] }
          } );  
          setPersStatusDB(persStatus);

          // contractstatus !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

          let contractstatus = {};
          response.forEach( ( eventObject ) => {
              if ( contractstatus.hasOwnProperty( eventObject.contractstatus ) ) {
                contractstatus[eventObject.contractstatus]++;
              } else {
                contractstatus[eventObject.contractstatus] = 1;
              }
          } );
          // console.log(year)
          // convert object to array of objects (if this is really needed)
          contractstatus = Object.keys( contractstatus ).map( ( key ) => { 
              return { 'label': key, 'value': contractstatus[key] }
          } );  
          setcontractstatusDB(contractstatus);

          // medicalstatus !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

          let medicalstatus = {};
          response.forEach( ( eventObject ) => {
              if ( medicalstatus.hasOwnProperty( eventObject.medicalstatus ) ) {
                medicalstatus[eventObject.medicalstatus]++;
              } else {
                medicalstatus[eventObject.medicalstatus] = 1;
              }
          } );
          // console.log(year)
          // convert object to array of objects (if this is really needed)
          medicalstatus = Object.keys( medicalstatus ).map( ( key ) => { 
              return { 'label': key, 'value': medicalstatus[key] }
          } );  
          setmedicalstatusDB(medicalstatus);

          // paymentstatus !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

          let paymentstatus = {};
          response.forEach( ( eventObject ) => {
              if ( paymentstatus.hasOwnProperty( eventObject.paymentstatus ) ) {
                paymentstatus[eventObject.paymentfuffiled]++;
              } else {
                paymentstatus[eventObject.paymentfuffiled] = 1;
              }
          } );
          // console.log(year)
          // convert object to array of objects (if this is really needed)
          paymentstatus = Object.keys( paymentstatus ).map( ( key ) => { 
              return { 'label': key, 'value': paymentstatus[key] }
          } );  
          setpaymentstatusDB(paymentstatus);
        // travelstatus !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

          let travelstatus = {};
          response.forEach( ( eventObject ) => {
              if ( travelstatus.hasOwnProperty( eventObject.travelstatus ) ) {
                travelstatus[eventObject.travelstatus]++;
              } else {
                travelstatus[eventObject.travelstatus] = 1;
              }
          } );
          // console.log(year)
          // convert object to array of objects (if this is really needed)
          travelstatus = Object.keys( travelstatus ).map( ( key ) => { 
              return { 'label': key, 'value': travelstatus[key] }
          } );  
          settravelstatusDB(travelstatus);

        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    GetInfo();
  }, []);
  
  
  // create categories object to count category items
  // if (userDB) {
  //   let year = {};
  //   userDB.forEach( ( eventObject ) => {
  //       if ( year.hasOwnProperty( eventObject.userYear ) ) {
  //         year[eventObject.userYear]++;
  //       } else {
  //         year[eventObject.userYear] = 1;
  //       }
  //   } );
  //   // console.log(year)
  //   // convert object to array of objects (if this is really needed)
  //   year = Object.keys( year ).map( ( key ) => { 
  //       return { 'label': key, 'value': year[key] }
  //   } );  
  //    setYearDB(year);
  // }


  return (
    <Box>

   
    <Box className ='pieCharts'>
<Card className ='pie' sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography>
        Total Users
        </Typography>
      {yearDB && ( 
  
<PieChart
  series={[
    {
      arcLabel: (item) => `${item.label} (${item.value})`,
      arcLabelMinAngle: 45,
      data: 
      yearDB
      ,
    },
  ]}
  sx={{
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontWeight: 'bold',
    },
  }}
  width={400}
  height={200}
/>
)}
      </CardContent>
  
    </Card>

    <Card className ='pie' sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography>
        Personal Profile Completed
        </Typography>
      {persStatusDB && ( 
  
<PieChart
  series={[
    {
      arcLabel: (item) => `${item.label} (${item.value})`,
      arcLabelMinAngle: 45,
      data: 
      persStatusDB
      ,
    },
  ]}
  sx={{
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontWeight: 'bold',
    },
  }}
  width={400}
  height={200}
/>
)}
      </CardContent>
  
    </Card>
    </Box>
    <Box className ='pieCharts'>
    <Card className ='pie'  sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography>
        Medical Profile Completed
        </Typography>
      {medicalstatusDB && ( 
  
<PieChart
  series={[
    {
      arcLabel: (item) => `${item.label} (${item.value})`,
      arcLabelMinAngle: 45,
      data: 
      medicalstatusDB
      ,
    },
  ]}
  sx={{
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontWeight: 'bold',
    },
  }}
  width={400}
  height={200}
/>
)}
      </CardContent>
  
    </Card>

    <Card className ='pie'  sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography>
        Payment Completed
        </Typography>
      {paymentstatusDB && ( 
  
<PieChart
  series={[
    {
      arcLabel: (item) => `${item.label} (${item.value})`,
      arcLabelMinAngle: 45,
      data: 
      paymentstatusDB
      ,
    },
  ]}
  sx={{
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontWeight: 'bold',
    },
  }}
  width={400}
  height={200}
/>
)}
      </CardContent>
  
    </Card> 
    </Box>
    <Box className ='pieCharts'>
    <Card className ='pie'  sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography>
        Travel Profile Completed
        </Typography>
      {travelstatusDB && ( 
  
<PieChart
  series={[
    {
      arcLabel: (item) => `${item.label} (${item.value})`,
      arcLabelMinAngle: 45,
      data: 
      travelstatusDB
      ,
    },
  ]}
  sx={{
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontWeight: 'bold',
    },
  }}
  width={400}
  height={200}
/>
)}
      </CardContent>
  
    </Card> 
    <Card className ='pie'  sx={{ minWidth: 275 }}>
      <CardContent>
      <Typography>
        Contract Profile Completed
        </Typography>
      {contractstatusDB && ( 
  
<PieChart
  series={[
    {
      arcLabel: (item) => `${item.label} (${item.value})`,
      arcLabelMinAngle: 45,
      data: 
      contractstatusDB
      ,
    },
  ]}
  sx={{
    [`& .${pieArcLabelClasses.root}`]: {
      fill: 'white',
      fontWeight: 'bold',
    },
  }}
  width={400}
  height={200}
/>
)}
      </CardContent>
  
    </Card>   
    </Box>
</Box>

 
// contractstatus  medicalstatus paymentstatus travelstatus
  )
}
