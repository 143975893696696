import React from 'react';

const FilePreview = ({filePath, fileName  }) => {

  return (
    <div >
    
      {/* Use an appropriate method to preview the file */}
      {/* {getFilePreviewComponent(fileName, downloadURL)} */}
      <a href={filePath} target="_blank" rel="noopener noreferrer" download>
      {fileName} Download
      </a>
    </div>
  );
};

// Define a function to render different components based on file type
// const getFilePreviewComponent = (fileName, downloadURL) => {
//   // Implement logic to determine the file type and return a corresponding component
//   // For example, display an image for image files, a video player for videos, etc.

//   // For simplicity, let's assume all files are images
//   return <img src={downloadURL} alt={fileName} style={{ maxWidth: '100%' }} />;
// };

export default FilePreview;
