import React from 'react'
import "pages/forbidden/index.scss"
import { Box } from '@mui/material'
export default function Forbidden() {
  return (
    
    <Box className="unauth">
        <h1>Unauthorised</h1>
    </Box>
  )
}
