import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Dialog,DialogTitle ,DialogContent ,DialogActions
  , TextField, Alert, DialogContentText, Typography } from '@mui/material';
import { DataGrid, GridToolbar, gridClasses } from '@mui/x-data-grid';
import { DBDash } from 'setup/db_calls';
import 'pages/admin_DB/index.scss'
import { GetUserDB } from 'setup/db_calls';
import pfp from "content/pfpnone.jpg"
import html2pdf from 'html2pdf.js';
import 'jspdf-autotable';
import FilePreview from 'components/filePreview';
import { AddPersonalNoti } from 'setup/db_writes';
import EmailUsers from 'components/emailUsers';


export default function AdminDB() {
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [data, setData] = useState("");
  const pdfRef = useRef()
  const [loadingPN, setLoadingPN] = useState(null);
  

  const [typeResult, setTypeResult] = useState('');

  const [open, setOpen] = useState(false);


  useEffect(() => {
    // console.log('Select Data loaded.');
    
       const GetInfo = async () => {
      try {
        
        const response = await GetUserDB(rowSelectionModel[0]);
        //  console.log(response)
        setData(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      
    };
    GetInfo();
    
   


    
  
  }, [rowSelectionModel]);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handlePersonalMess = (event) => {
    // console.log(event.target.value)
    setTypeResult(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openInner, setOpenInner] = useState(false);

  const handleClickOpenInner = () => {
    setLoadingPN(null)
    setOpenInner(true);
  };
  const handleClickSendPersonal = () => {
    setLoadingPN('wait')
    const mess = `Good day,
  <strong>You have a new Personal Notification</strong></br><br> 
  Go to  <a href="https://login.symco.co.za">Login</a> to view your notification! <br><br> 
  Thank you<br> 
  The Symco Team`;
;
    
    if (typeResult !=="")
    {
      
    const awe = async () => {const added =await AddPersonalNoti(rowSelectionModel,typeResult)
       if (added === 'true')
    {
      
      EmailUsers("Symco: Personal Notification",mess,rowSelectionModel[0]).then(function(response2) {

        if (response2.status=== 400) {
          setLoadingPN("Notification Added But :[INPUT PARAMETER ERROR]There was a Error Sending the Email.")
        }
        if (response2.status=== 401) {
          setLoadingPN("Notification Added But :There was a Error Sending the Email Please contact ExcuTech")
        }
        if (response2.status=== 200) {
          setLoadingPN('succesfull')
          // setUserError(null)
        }
        else {
          setLoadingPN("Notification Added But :There was a Error Sending the Email ")
        } 

      })
      
      // setOpenInner(false);
    }
    else{
      setLoadingPN(added.toString())
    }
    
    }
    awe()
    
  }
  else{
    setLoadingPN("Cant be Blank")
  }
    
  };
  const handleCloseInner = () => {
    setOpenInner(false);
  };
 
  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  const convertHTMLtoPDF = () => {
    const element = document.getElementById('divID');
  
    if (!element) {
      console.error('Could not find the target HTML element');
      return;
    }
  
    try {
      const pdfOptions = {
        margin: 10,
        filename: data.userprefname?data.userprefname:"newpdf",
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
        pagebreak: { mode: ['avoid-all'] }, 
        
      };
  
      // Call html2pdf directly on the element
      html2pdf(element, pdfOptions).then((pdf) => {
        pdf.save();
      });
    } catch (error) {
      console.error('Error converting HTML to PDF:', error);
    }
  };
  //!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  
  return (
    <Box className='AdminDB'>
      <Dialog
        
        open={open}
        onClose={handleClose}
        
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          User Information
        </DialogTitle>
        <DialogContent>
        {data? (<Box><Box id="divID" ref={pdfRef}>
      <Typography variant="h5"> Personal Information</Typography> 
      {data.userpfp?(<img
                  alt={'unable to get'}
                  width="80px"
                  height="80px"
                  src={data.userpfp}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />)
              :(<img
                alt={'unable to get'}
                width="80px"
                height="80px"
                src={pfp}
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />)}
        <FilePreview filePath={data.userpfp} fileName={"Profile Pic"}  />

                <Typography>Full Name : {data.userfullname}</Typography>
                <Typography>Preffered Name : {data.userprefname}</Typography>
                <Typography>Surname : {data.userSurname}</Typography>
                <Typography> Contact Number : {data.usercontactnumber}</Typography>
                <Typography> Delegate Year : {data.userYear}</Typography>
                <Typography>Email Address : {rowSelectionModel}</Typography>
                <Typography>Country : {data.userCountry}</Typography>
                <Typography> University :  {data.useruniversity}</Typography> 
                <Typography>Personal Info Completed : {data.userstatus?"Yes":"No"}</Typography>
                <Typography mt="2rem" variant="h5"> ID Documents</Typography> 
                    {data.ID && data.ID.map((url, index) => (
                      <FilePreview filePath={url} fileName={"ID "+index} key={index} />
                      ))}
                <Typography mt="2rem" variant="h5"> Passport Documents</Typography> 
                    {data.userpassport && data.userpassport.map((url, index) => (
                      <FilePreview filePath={url} fileName={"Passport "+index} key={index} />
                      ))}

                
                <Typography mt="2rem" variant="h5"> Medical Information</Typography> 
                
                <Typography >Allergies : {data.medicalallergies}</Typography>
                <Typography>Dietary Requirements : {data.medicaldietaryreq}</Typography>
                <Typography>Medical Info Completed : {data.medicalstatus?"Yes":"No"}</Typography>
                <Typography mt="2rem" variant="h5"> Medical Documents</Typography> 
                  {data.medicaldoc && data.medicaldoc.map((url, index) => (
                    <FilePreview filePath={url} fileName={"Medical "+index} key={index} />
                    ))}

                <Typography mt="2rem" variant="h5"> Travel Information</Typography> 
                
                <Typography >Departure Date : {new Date(data.traveldeparturedate.seconds * 1000)
                                .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'})}</Typography>
                <Typography>Arrival Date : {new Date(data.travelarrivaldate.seconds * 1000)
                                .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'})}</Typography>
                <Typography>Travel Info Completed : {data.travelstatus?"Yes":"No"}</Typography>
                <Typography mt="2rem" variant="h5"> Travel Documents</Typography> 
                  {data.travelDOC && data.travelDOC.map((url, index) => (
                    <FilePreview filePath={url} fileName={"Travel "+index} key={index} />
                    ))}

                <Typography mt="2rem" variant="h5"> Payement Information</Typography> 
                
                <Typography >Payment Plan : {data.paymentplan}</Typography>
                <Typography>Payment Fuffiled : {data.paymentfuffiled?"Yes":"No"}</Typography>
                <Typography>Payment Info Completed : {data.paymentstatus?"Yes":"No"}</Typography>
                <Typography mt="2rem" variant="h5"> Payment Documents</Typography> 
                  {data.paymentdoc && data.paymentdoc.map((url, index) => (
                    <FilePreview filePath={url} fileName={"Payment "+index} key={index} />
                    ))}
                  <Typography mt="2rem" variant="h5"> Contract</Typography> 
                  {data.contractdoc && data.contractdoc.map((url, index) => (
                    <FilePreview filePath={url} fileName={"Contract "+index} key={index} />
                    ))}
                <Typography mt="2rem" variant="h5"> Personal Notifications</Typography> 
                {data.personalNotifications && data.personalNotifications
                  .map((url, index) => (
                    <div key={index} > {new Date(url.personalNotificationsDate.seconds*1000)
                      .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'})+" : " + url.personalNotificationsText}</div>
                    ))}
{/* new Date(url.personalNotificationsDate.seconds*1000)
                      .toLocaleString('en-gb',{timeZone:'Africa/Johannesburg'}) */}
                
        </Box> 
        
        </Box> 
          ): (
            <DialogContentText>Loading...</DialogContentText>
          )}

        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClickOpenInner}>
            Peronal Notification
          </Button>
          <Button onClick={convertHTMLtoPDF} autoFocus>
            PDF Download
          </Button>
        </DialogActions>
      </Dialog>

    {/* DIALOG INNER Personal Notifications */}
    <Dialog open={openInner} onClose={handleCloseInner}>
        <DialogTitle>Send Personal Notification</DialogTitle>
        
        <DialogContent>
        {(loadingPN=== null)?("Please Insert your Personal Message"):(loadingPN=== "wait")?(<Alert severity="info">
          Loading 
        </Alert>):(loadingPN=== "succesfull")?
        (<Alert severity="success">
         succesfull
        </Alert>):(<Alert severity="error"> 
          {loadingPN}
        </Alert>)
        }
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Insert your message here..."
            type="text"
            fullWidth
            onChange={handlePersonalMess}

            variant="standard"
          />
        </DialogContent>
        {loadingPN=== "succesfull"?(<DialogActions>
        
          <Button onClick={handleCloseInner}>close</Button>
       
        </DialogActions>)
        :(<DialogActions>
         <Button onClick={handleCloseInner}>Cancel</Button>
        <Button onClick={handleClickSendPersonal}>Send</Button>
          </DialogActions> 
          )}
          
        
      </Dialog>
    {/* END DIALOG INNER Personal Notifications */}
    {/* DB ON PAGE */}
        <Box className='db'>
              <Box sx={{ height: '100%', width: '100%', [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
            outline: 'none',
          },
          [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
            {
              outline: 'none',
            }, }}>
                  <DataGrid
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal"
                    },
                    "& .MuiDataGrid-columnHeader": {
                      // Forced to use important since overriding inline styles
                      height: "unset !important"
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      // Forced to use important since overriding inline styles
                      maxHeight: "168px !important"
                    },
                    "& .MuiDataGrid-columnHeadersInner":{
                      height:"100% !important"
                    },
                    "& .MuiDataGrid-columnHeaderRow":{
                      height:"100% !important"
                    },
                    "& .MuiDataGrid-columnHeaderTitleContainer":{
                      display:"flex",
                      flexDirection:"column",
                      alignContent:"center"
                    }

                    
                  }}
                    autoPageSize
                    disableColumnFilter
                    disableColumnSelector
                    
                    disableDensitySelector
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                      setRowSelectionModel(newRowSelectionModel);
                      handleClickOpen()
                        console.log(newRowSelectionModel)
                    }}
                    rowSelectionModel={rowSelectionModel}

                    columns={[
                      { field: 'Year', valueGetter:(params) => params.row.userYear, minWidth: 70 },

                      { field: 'Email', valueGetter:(params) => params.row.id , minWidth: 200},
                      { field: 'Preffered Name', valueGetter:(params) => params.row.userprefname ,minWidth: 100},

                      { field: 'FullName', valueGetter:(params) => params.row.userfullname,minWidth: 150},
                      { field: 'Surname', valueGetter:(params) => params.row.userSurname ,minWidth: 100},
                      { field: 'Contact Number', valueGetter:(params) => params.row.usercontactnumber,minWidth: 100},
                      
                      { field: 'User Status',type: 'boolean', valueGetter:(params) => params.row.userstatus,minWidth: 100},

                      { field: 'Medical Status',type: 'boolean', valueGetter:(params) => params.row.medicalstatus,minWidth: 80 },
                      { field: 'Payment Completed',type: 'boolean', valueGetter:(params) => params.row.paymentfuffiled,minWidth: 100},
                      { field: 'Travel Status',type: 'boolean', valueGetter:(params) => params.row.travelstatus,minWidth: 100},
                      { field: 'Arrival Date' , type: 'datetime',valueGetter: ( params ) =>  new Date(params.row.travelarrivaldate.seconds*1000) ,minWidth: 250},
                      { field: 'Departure Date' , type: 'datetime',valueGetter: ( params ) => new Date(params.row.traveldeparturedate.seconds*1000) ,minWidth: 250},
                      { field: 'Country', valueGetter:(params) => params.row.userCountry,minWidth: 100},

                       { field: 'University', valueGetter:(params) => params.row.useruniversity,minWidth: 100},

                    ]}
                    rows={DBDash()}
                    getRowId={(row) => row.id}
                    slots={{ toolbar: GridToolbar }}
                    slotProps={{
                      toolbar: {
                        showQuickFilter: true,
                      },
                    }}
                  />
                  </Box>
            </Box>
            {/* END DB ON PAGE  */}
    </Box>
  )
}
