import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from '@mui/material/Alert';
import 'pages/login/index.scss'
import React, { useRef, useState } from "react"
import { useAuth } from "setup/auth";

import { useLocation, useNavigate} from "react-router-dom";

import symco from 'content/symcoicon.png'

export default function Login() {

  const emailRef = useRef()
  const passwordRef = useRef()
  const { login} = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/delegate-dashboard"

  async function handleSubmit (event) {
    event.preventDefault();
    setError("")
    setLoading(true)
    try {
     const logintext = await login(emailRef.current.value, passwordRef.current.value)  
     console.log(logintext) 
       navigate(from, {replace:true})
      setLoading(false)
    } catch (error ) {
      if (error.code == 'auth/invalid-email')
    {
      setError("Invalid Email or Password")
      setLoading(false)}
      if (error.code == 'auth/invalid-credential')
    {
      setError("Invalid Password")
      setLoading(false)}
      else{
        setError(error.code)
        setLoading(false)
      }
    
    }
    
  
  };

  return (
    <Box className="login">
      <Box className = "leftbox">
        <Box
            className="image"
            component="img"    
            alt="The house from the offer."
            src= {symco}
        />
      </Box>
      <Box className = "loginBox">
        <Box className = "loginInnerBox">
          <Container component="main" maxWidth="xs">
            <Box
              sx={{  
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
              > 
              <Typography component="h1" variant="h5">
                  Sign in
              </Typography>
              {error && <Alert  severity="error" >{error}</Alert>}
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  inputRef={emailRef}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  inputRef={passwordRef}
                />
           
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading}
                >
                  Sign In
                </Button>
                <Grid container>
                  <Grid item xs>
                    <Link href="/forgot-password" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                  <Grid item>
                    <Link href="/sign-up" variant="body2">
                        {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Box>
  );
}