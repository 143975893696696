import Topbar from "components/navbar";
import Sidebar1 from "components/sidebar";
import { useState } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "setup/auth";
import "setup/requireAuth/index.scss"
import FloatingActionButtonSize from "components/floatButton";

const RequireAuth = ({allowedRoles}) => {
    const {currentUser, userType, GetUserType } = useAuth();
    const location = useLocation();
    const [isSidebar, setIsSidebar] = useState(true);
    // const [userT, setuserT] = useState([]);

        try {
            
           return ( currentUser
          ?(userType.find(item => allowedRoles.includes(item) ) )         
            ?   <div className="app">
                <div className="sidebar">
                <Sidebar1 sidebar={userType.find(item => item === "2009" )} />
                </div>
                <main className="content">
                <Topbar setIsSidebar={isSidebar} />
                <FloatingActionButtonSize/>
                    <Outlet/>
                    </main>
                </div>
            
            : <Navigate to="/403" state={{from:location}} replace/>
        : <Navigate to="/" state={{from:location}} replace/>
        )
        } catch (error) {
                GetUserType(currentUser).then(data => { return ( currentUser
                    ?(data.find(item => allowedRoles.includes(item) ) )
                      ?   <div className="app">
                          <div className="sidebar">
                          <Sidebar1 sidebar={data.find(item => item === "2009" )} />
                          </div>
                          <main className="content">
                          <Topbar setIsSidebar={isSidebar} />
                          <FloatingActionButtonSize/>
                              <Outlet/>
                              </main>
                          </div>
                      
                      : <Navigate to="/403" state={{from:location}} replace/>
                  : <Navigate to="/" state={{from:location}} replace/>
                  ) 

                })

           
        }
        
        
    
}
export default RequireAuth;