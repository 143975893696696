import * as React from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import "components/floatButton/index.scss"
import { useState } from 'react';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import wa from 'content/wa.png'
export default function FloatingActionButtonSize() {
    const [open, setOpen] = useState(false);
    const [openUser1, setOpenUser1] = useState(false);

    const [openUser2, setOpenUser2] = useState(false);

    const [openUser3, setOpenUser3] = useState(false);


    const openSubComp = () => {
        setOpen(!open)
        // setOpenInner(true);
      };
      const openSubCompUser1 = () => {
        setOpenUser1(!openUser1)
        // setOpenInner(true);
      };
      const openSubCompUser2 = () => {
        setOpenUser2(!openUser2)
        // setOpenInner(true);
      };
      const openSubCompUser3 = () => {
        setOpenUser3(!openUser3)
        // setOpenInner(true);
      };
      const openInNewTab = (url) => {
        window.open(url, '_blank', 'noreferrer');
      };
     
{/* <a href="https://bobbyhadz.com" target="_blank" rel="noopener noreferrer"></a> 
() => openInNewTab('https://wa.me/27824676853?text='+ encodeURIComponent("Hello Juron, I need help in regards with Symco"))
*/}
  return (
    <Box sx={{ '& > :not(style)': { m: 1 } }} className="floatbuttoncomp">
        <Box className="floatbuttoncomp1">



        {open && (<Box className="floatbuttonclicked">
            <Box className="floatbuttonclickedUser">
            {openUser1&&(
                        <Box className="floatbuttonclickedUSerInner">
                            <Fab className="fab" onClick={() => window.location = 'mailto:werner@symco.co.za'} size="small" color="secondary" aria-label="add">
                            <EmailOutlinedIcon />
                            </Fab>
                            <Fab className="fab" onClick={() => openInNewTab('https://wa.me/27646544929?text='+ encodeURIComponent("Hello Werner, I require assistance regarding Symco."))} size="small" color="secondary" aria-label="add">
                            <img  src={wa} />
                            </Fab>
                            <Fab className="fab" onClick={() => openInNewTab('https://t.me/WernerGuldenpfennig')} size="small" color="secondary" aria-label="add">
                                <SendOutlinedIcon />
                            </Fab>
                        </Box>
                    )}
                    <Fab className="fab" onClick={openSubCompUser1} size="small" color="secondary" aria-label="add">
                        W
                    </Fab>
                    


            </Box>
            <Box className="floatbuttonclickedUser">
            {openUser2&&(
                        <Box className="floatbuttonclickedUSerInner">
                            <Fab className="fab" onClick={() => window.location = 'mailto:janine@symco.co.za'} size="small" color="secondary" aria-label="add">
                            <EmailOutlinedIcon />
                            </Fab>
                            <Fab className="fab" onClick={() => openInNewTab('https://wa.me/27722726611?text='+ encodeURIComponent("Hello Janine, I require assistance regarding Symco."))} size="small" color="secondary" aria-label="add">
                            <img  src={wa} />
                            </Fab>
                            <Fab className="fab" onClick={() => openInNewTab('https://t.me/JanineMoolman')} size="small" color="secondary" aria-label="add">
                                <SendOutlinedIcon />
                            </Fab>
                        </Box>
                    )}
            <Fab className="fab" onClick={openSubCompUser2} size="small" color="secondary" aria-label="add">
                J
            </Fab>
            </Box>
            <Box className="floatbuttonclickedUser">
            {openUser3&&(
                        <Box className="floatbuttonclickedUSerInner">
                            <Fab className="fab" onClick={() => window.location = 'mailto:cybill@symco.co.za'} size="small" color="secondary" aria-label="add">
                            <EmailOutlinedIcon />
                            </Fab>
                            <Fab className="fab" onClick={() => openInNewTab('https://wa.me/27763936940?text='+ encodeURIComponent("Hello Cybill, I require assistance regarding Symco."))} size="small" color="secondary" aria-label="add">
                            <img  src={wa} />
                            </Fab>
                            <Fab className="fab" onClick={() => openInNewTab('https://t.me/JanineMoolman')} size="small" color="secondary" aria-label="add">
                                <SendOutlinedIcon />
                            </Fab>
                        </Box>
                    )}
            <Fab className="fab" onClick={openSubCompUser3} size="small" color="secondary" aria-label="add">
               C
            </Fab>
            </Box>
           
        </Box>)}        
        <Box className="floatbutton">
            <Fab className="fab" onClick={openSubComp} color="secondary" aria-label="add">
                <ContactSupportOutlinedIcon />
            </Fab>
        </Box>
        </Box>
    </Box>
  );
}