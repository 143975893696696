import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

//  !!!!!!! LIVE !!!!!!!
const firebaseConfig = {
  apiKey: "AIzaSyDnsj0VxqsLFm1lzXH0Ifg_y9z5XdxgJ58",
  authDomain: "symcodb.firebaseapp.com",
  projectId: "symcodb",
  storageBucket: "symcodb.appspot.com",
  messagingSenderId: "1075699809400",
  appId: "1:1075699809400:web:49f35dfe2e17c78f7ec18d",
  measurementId: "G-W31MJRX7FM",
};
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// const firebaseConfig = {
//   apiKey: "AIzaSyB0hPw0yzMJgSnIeUcaEqnP6cIyFMDTnSA",
//   authDomain: "symcotest.firebaseapp.com",
//   projectId: "symcotest",
//   storageBucket: "symcotest.appspot.com",
//   messagingSenderId: "59547170323",
//   appId: "1:59547170323:web:23361cf5c311643403f1e6"
// };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export default firebaseApp;
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
// const analytics = getAnalytics(app);
