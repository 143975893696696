import React, { useState } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert, Button, TextField } from '@mui/material';
import { collection, doc, setDoc, getFirestore, Timestamp, query, where, getDocs } from "firebase/firestore"; 
import firebaseApp from "setup/firebase";
import EmailUsers from 'components/emailUsers/index.js';
import "pages/notifications/index.scss"

export default function Index() {
  const [notificationInfo, setNotificationInfo] = useState('');
  const [notificationHeader, setNotificationHeader] = useState('');

  const [notificationLink, setNotificationLink] = useState('');

  const [notificationYear, setNotificationYear] = useState('');
  const [success, setsuccess] = useState(null);

  const db = getFirestore(firebaseApp);

  const handleInfoChange = (event) => {
    setNotificationInfo(event.target.value);
  };
  const handleLinkChange = (event) => {
    setNotificationLink(event.target.value);
  };
  const handleHeaderChange = (event) => {
    setNotificationHeader(event.target.value);
  };

  const handleYearChange = (event) => {
    setNotificationYear(event.target.value);
  };

  const getUsersByYear = async (year) => {
    const usersRef = collection(db, "Users");
    const q = query(usersRef, where("userYear", "==", year));
    const querySnapshot = await getDocs(q);
    const userEmails = [];
    querySnapshot.forEach((doc) => {
      userEmails.push(doc.id); 
    });
    console.log("Fetched emails: ", userEmails); 
    return userEmails;
  };

  const sendEmails = async (userEmails) => {
    const subject = 'Symco Notification'; 
    const message =  `Good day,
    <strong>You have a new Notification</strong></br><br> 
    Go to  <a href="https://login.symco.co.za">Login</a> to view your notification! <br><br> 
    Thank you<br> 
    The Symco Team`;
  ;; 

    try {
      const response = await EmailUsers(subject, message, userEmails.join(','));
      console.log('Emails sent successfully', response);
    } catch (error) {
      console.error('Error sending emails:', error);
    }
  };

  const handleSubmit = async () => {
    setsuccess('Loading');
    
    if (notificationHeader !== null && notificationHeader.trim() !== '' &&
    notificationInfo !== null && notificationInfo.trim() !== '' &&
    notificationYear !== null && notificationYear.trim() !== '') {
    try {
      
      const newNotification = {
        notificationHeader: notificationHeader,
        notificationInfo: notificationInfo,
        notificationLink: notificationLink === "" ? "NA" :notificationLink ,
        notificationTimeDate: Timestamp.fromDate(new Date()),
        notificationYear: notificationYear,
      };
  
      // const docRef = doc(collection(db, "Notifications"));
      // console.log(new Date())
      var date = new Date();
      var seconds = date.getTime(); //1440516958
      await setDoc(doc(db, "Notifications", seconds.toString()), newNotification);
      // await setDoc(docRef, newNotification);
      // console.log('Notification added successfully, Document ID:', docRef.id);

      const userEmails = await getUsersByYear(notificationYear);
    
      await sendEmails(userEmails);
setsuccess('Success')
    } catch (error) {
      console.error('Error:', error);
      setsuccess('Failed')
    }}
   else {
    setsuccess('Required')
    // setUserError("Please make sure Email, User Type and Year added")
  }
  };

  return (
    <Box className="notifications">
    <Box className="notifications-inner">
      <h1>Notifications</h1>
      <Box className="select">
        <InputLabel id="demo-simple-select-label">Year</InputLabel>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            className="selectIn"
            value={notificationYear}
            label="Year"
            onChange={handleYearChange}
          >
            <MenuItem value="2023">2023</MenuItem>
            <MenuItem value="2024">2024</MenuItem>
            <MenuItem value="2025">2025</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box className="textarebox">
      <TextField
          className="TextField"
          fullWidth 
          id="outlined-textarea1"
          label="Header"
          placeholder="Bold Text"
          multiline
          value={notificationHeader} 
          onChange={handleHeaderChange}
          required
        />
        <TextField
        className="TextField"
          fullWidth 
          id="outlined-textarea2"
          label="Description"
          placeholder="More Information"
          multiline
          value={notificationInfo} 
          onChange={handleInfoChange}
          required
        />
        <TextField
        className="TextField"
          fullWidth 
          id="outlined-textarea3"
          label="Link (Please include whole link like: https://www.google.com/ )"
          placeholder="Link"
          multiline
          value={notificationLink} 
          onChange={handleLinkChange}
        />
        {/* <textarea className="input-label" value={notificationInfo} onChange={handleInfoChange} /> */}
      </Box>

      

      <Box className="buttonbox">
        <Button onClick={handleSubmit} >
          Send
        </Button>
        {success==='Loading' && (<Alert variant="outlined" severity="info">loading</Alert>)}
        {success==='Success' && (<Alert variant="outlined" severity="success">Notification and Email: Successfull</Alert>)}
        {success==='Failed' && (<Alert variant="outlined" severity="error">Notification and Email:Failed </Alert>)}
        {success==='Required' && (<Alert variant="outlined" severity="error">Please make sure Year, Header and Description is filled in</Alert>)}


        
      </Box>
    </Box>
    </Box>

  );
}
