import { Box, IconButton, Menu, Typography } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import logo from "content/symcoicon.png"
import "components/navbar/index.scss"
import { useAuth } from "setup/auth";
const Topbar = () => {

  const { logout  } = useAuth()

  async function  logg() {

    try {
      await logout()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Box className = "navbarBox"  p={2}>
      {/* SEARCH BAR */}
      <Box className ="logo">
       <img
                  alt="profile-user" 
                  src={logo}
                />
      </Box>

      {/* ICONS */}
      <Box className= "rightBox" >
        <IconButton onClick={logg}>
          <Typography className="innerText">
            Sign Out
          </Typography>
          <ExitToAppIcon className="innerButton" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
