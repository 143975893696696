import { doc, updateDoc, getDoc, getFirestore, getDocs, collection } from "firebase/firestore";
import firebaseApp from "setup/firebase";
import { useEffect, useState } from "react";

const db = getFirestore(firebaseApp);

export async function GetPersonalNotifications(currentUser) {
   
    
try {
  if (currentUser) {
    try {
      const ref = doc(db, "Users", currentUser.email);
      const docSnap = await getDoc(ref)
      if (docSnap.exists()) {
         return docSnap.data().personalNotifications   
      } else {  
       return []
      } 
    } catch (error) {
      console.log(error)
        
      return []
    }
  }
} catch (error2) {
  console.log(error2)
}
}
export async function GetUserDB(ddd) {
try {
  if (ddd) {
    try {
      const ref = doc(db, "Users", ddd);
      const docSnap = await getDoc(ref)
      if (docSnap.exists()) {
         return docSnap.data()  
      } else {
        return []
      } 
    } catch (error) {
      console.log(error)
      return []
    }
  }
} catch (error2) {
  console.log(error2)
}
}

export async function GetNotifications() {
          try {
            const docSnap = await getDocs(collection(db, "Notifications"))
                          
                const newData = docSnap.docs
                    .map((doc) => ({ id:doc.id 
                       ,notificationInfo:doc.data().notificationInfo
                       ,notificationHeader:doc.data().notificationHeader
                       ,notificationLink: doc.data().notificationLink

                       ,notificationTimeDate:  doc.data().notificationTimeDate.seconds
                      ,
                      notificationYear:doc.data().notificationYear, }));                  
                return newData     
          } catch (error) {
            console.log(error)
               
            return []
          }
        }

export async function Dblocations() {

  try {
    const docSnap = await getDocs(collection(db, "Users"))           
        const newData = docSnap.docs
            .map((doc) => ({ id:doc.id ,userYear:doc.data().userYear,type:doc.data().usertype, }));
        return newData
   
  } catch (error) {
    console.log(error)
    return []
  }
}

export function DBDash() {
  const [todos, setTodos] = useState([]);
  const fetchPost = async () => {
     await getDocs(collection(db, "Users"))
              .then((querySnapshot)=>{               
                  const newData = querySnapshot.docs
                      .map((doc) => (
                        {id:doc.id
                             
                           ,...doc.data()

                        }));
                  setTodos(newData);     
              })
   }
      useEffect(()=>{
        fetchPost();
        }, [])
    return (todos)
  
}
 

  export async function fetchUserData(email) {
    if (!email) {
      console.error('Invalid or missing email in fetchUserData');
      return null;}
  try {
    const docRef = doc(db, "Users", email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error('User document does not exist in Firestore:', email);
      return null;
    }
  } catch (error) {
    console.error('Error while fetching user data:', error);
    return null;
  }
}

export default async function emailAlreadyExists(email) {
  if (!email) {
    console.error('Invalid or missing email in emailAlreadyExists');
    return null;
  }

  try {
    const docRef = doc(db, "Users", email);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.error('User document does not exist in Firestore:', email);
      return null;
    }
  } catch (error) {
    console.error('Error while fetching user data:', error);
    return null;
  }
}

export async function updateUserDetails(email, updatedData) {
  const userRef = doc(db, "Users", email);

  console.log("Updating user details for:", email, "with data:", updatedData);

  try {
    await updateDoc(userRef, updatedData);
    console.log("User details updated successfully");
   
  } catch (error) {
    console.error("Error updating user details:", error);
    
  }
}
export async function DBDashStats() {
  // const [todos, setTodos] = useState([]);
  
    //  await getDocs(collection(db, "Users"))
    //           .then((querySnapshot)=>{               
    //               const newData = querySnapshot.docs
    //                   .map((doc) => (
    //                     {id:doc.id
                             
    //                        ,...doc.data()

    //                     }));
    //               return(newData);     
    //           })
   
  
    const querySnapshot = await getDocs(collection(db, "Users"));
  // querySnapshot.forEach((doc) => {
  // doc.data() is never undefined for query doc snapshots
  const newData = querySnapshot.docs
                      .map((doc) => (
                        {id:doc.id
                             
                           ,...doc.data()

                        }));
                  return (newData)
// }
// );

     
  
}