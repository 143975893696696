import { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box,  IconButton, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import "components/sidebar/index.scss" 
import pfp from "content/pfpnone.jpg"
import { useAuth } from "setup/auth";

// MUI ICONS IMPORT
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import LocalAirportOutlinedIcon from '@mui/icons-material/LocalAirportOutlined';
import GavelOutlinedIcon from '@mui/icons-material/GavelOutlined';
import LineAxisOutlinedIcon from '@mui/icons-material/LineAxisOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import SmsOutlinedIcon from '@mui/icons-material/SmsOutlined';
// END MUI ICONS IMPORT


const Item = ({ title, to, icon, selected, setSelected }) => {

  return (
    <MenuItem
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={icon}
      component={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

function Sidebar1({sidebar}) {
  const { logout ,userInfo } = useAuth()
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
async function  logg() {
  try {
    await logout()
  } catch (error) {
    console.log(error)
  }
}
  return (
    <Box
    className='Sidebar'>
      <Sidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "5px 0 2px 0",
              // color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h4" >
                  Symco
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="15px">
              <Box display="flex" justifyContent="center" alignItems="center">
                {userInfo.userpfp?(<img
                  alt={'unable to get'}
                  width="80px"
                  height="80px"
                  src={userInfo.userpfp}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />)
              :(<img
                alt={'unable to get'}
                width="80px"
                height="80px"
                src={pfp}
                style={{ cursor: "pointer", borderRadius: "50%" }}
              />)}
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                 {userInfo.userprefname
}
                </Typography>
                
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/delegate-dashboard"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
           {!isCollapsed && (
            <Typography
              variant="h6"
              sx={{ m: "15px 0 5px 20px" }}
            >
              Profile
            </Typography>
            )}
            <Item
              title="Personal Profile"
              to="/personal-profile"
              icon={<PersonOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Medical Profile"
              to="/medical-profile"
              icon={<MedicalServicesOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Payment"
              to="/payment-profile"
              icon={<AccountBalanceOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            
            <Item
              title="Travel Information"
              to="/travel-profile"
              icon={<LocalAirportOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             <Item
              title="Contract Information"
              to="/contract-profile"
              icon={<GavelOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          {sidebar && (
          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
          {!isCollapsed && (
          <Typography
              variant="h6"
              sx={{ m: "15px 0 5px 20px" }}
            >
              Admin
            </Typography>
            )}
            <Item
              title="Admin Dashboard"
              to="/admin-dashboard"
              icon={<LineAxisOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Admin Database"
              to="/admin-database"
              icon={<AdminPanelSettingsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="User Management"
              to="/admin-user-management"
              icon={<ManageAccountsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Notifications"
              to="/notifications"
              icon={<SmsOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
             
            </Box>
            
            )}

          <Box sx={{ mt:'20px'}} paddingLeft={isCollapsed ? undefined : "10%"}>
        
            <MenuItem
            onClick={logg}
            icon={<ExitToAppIcon />}
              >
              <Typography>Sign Out</Typography>
          </MenuItem>
        
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default Sidebar1;